.NavBar {
    z-index: 30;
    padding: 0.3rem;

    .NavBar-inner {
        padding: 0.2rem;
        background-color: rgb(255, 255, 255, 0.2);
    
        .NavBar-companyLogo {
            img {
                width: 5rem;
            }
        }

        .NavBar-navMenu {
            /*position: relative;*/
            position: absolute;
        }
    }
}

@media only screen and (min-width: 40rem) {
    .NavBar {
        padding: 0.3rem;

        .NavBar-inner {
            padding: 0.4rem;
        }
    }
}

@media only screen and (min-width: 48rem) {
    .NavBar {
        padding: 0.8rem;

        .NavBar-inner {
            padding: 0.5rem;
        }
    }
}
